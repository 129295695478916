import Vec2 from "./Vec2";

function isInsideSector(point : Vec2, center : Vec2, sectorStart : Vec2, sectorEnd : Vec2, radiusSquared : number) : boolean
{
    let relPoint : Vec2 = new Vec2(0,0);
     
    relPoint.x = point.x - center.x;
    relPoint.y = point.y - center.y;

    return !areClockwise(sectorStart, relPoint) &&
           areClockwise(sectorEnd, relPoint);
  }

  function areClockwise(v1 : Vec2, v2 : Vec2) : boolean
  {
    return -v1.x*v2.y + v1.y*v2.x > 0;
  }
  export default isInsideSector;
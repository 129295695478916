import Vec2 from "../utils/Vec2";
import isInsideSector from "../utils/CircularSector";
import Theme from "../utils/Theme";

const thirtyDegrees : number = 5.23;
class Boid
{
    id : number;
    center : Vec2 = new Vec2(0, 0);
    directionVec: Vec2 = new Vec2(0, 0);
    direction : number = 0;
    speed : number = 2.3;
    color : string = Theme.Melanie;

    constructor(key : number)
    {
        this.id = key;
        if (!key)
            this.color = Theme.OxfordBlue;
    }
    clone(model : Boid) :Boid{
        this.id = model.id;
        this.center = model.center;
        this.directionVec = model.directionVec;
        this.direction = model.direction;
        this.speed = model.speed;
        this.color = model.color;
        return this;
    }
    wrap(width : number, height : number) : void
    {
        if (this.center.y <= 0)
            this.center.y = height - 1;

        if (this.center.y >= height)
            this.center.y = 1;

        if (this.center.x <= 0)
            this.center.x = width -1;
        
        if (this.center.x >= width)
            this.center.x = 1;
    }
    move() : void
    {
        this.direction = Math.atan2(this.directionVec.y, this.directionVec.x);
        this.center.x += this.directionVec.x * this.speed;
        this.center.y += this.directionVec.y * this.speed;
    }
    getPoints() : number[]
    {
        let sub : number = this.direction - 0.523599;
        let add : number = this.direction + 0.523599;
        let hx : number = this.center.x + Math.cos(this.direction) * 10;
        let hy : number = this.center.y + Math.sin(this.direction) * 10;
        
        let lx : number = this.center.x - Math.cos(sub) * 10;
        let ly : number = this.center.y - Math.sin(sub) * 10;
        let rx : number = this.center.x - Math.cos(add) * 10;
        let ry : number = this.center.y - Math.sin(add) * 10;

       
        return [hx, hy, lx, ly, this.center.x, this.center.y, rx, ry, hx, hy];
    }
    hasInFOV(target : Boid) :boolean
    {
        let viewDist : number = 100;
        if (!this.id){
            target.color = Theme.Melanie;
        }
        const dist : number = this.center.dist(target.center);
        if (dist < 50)
             return true;
        else if (dist >= viewDist)
            return false;

        let sectorStart : Vec2 = new Vec2(0,0);
        let sectorEnd : Vec2 = new Vec2(0,0);
        let sub : number = this.direction - thirtyDegrees;
        let add : number = this.direction + thirtyDegrees;
        sectorStart.x = Math.cos(sub) * viewDist;
        sectorStart.y = Math.sin(sub) * viewDist;
        sectorEnd.x = Math.cos(add) * viewDist;
        sectorEnd.y = Math.sin(add) * viewDist;

        if (isInsideSector(target.center, this.center, sectorStart, sectorEnd, viewDist*viewDist))
            return false;
        if (!this.id)
            target.color = Theme.MidGray;
        return true;
    }
    separate(flock : Boid[]) : void
    {
        for (let i : number = 0; i < flock.length; i++){
                let avoidForce : Vec2 = new Vec2(0,0);
                avoidForce.x = this.center.x - flock[i].center.x;
                avoidForce.y = this.center.y - flock[i].center.y;
                avoidForce.normalize();
                avoidForce.scale(0.1);
                this.directionVec.add(avoidForce);
        }

    }
    align(flock : Boid[]) : void
    {
        let average : Vec2 = averageBoidsDirection(flock, this);
        average.normalize();
        average.scale(0.1);

        this.directionVec.add(average);
    }
    cohesion(flock : Boid[]) :void
    {
        let center : Vec2 = findCenter(flock, this);
        center.sub(this.center);
        center.normalize();
        center.scale(0.1);
        this.directionVec.add(center);
    }
    explore()
    { 
        let sign : number = 1;
        if (Math.floor(Math.random()))
            sign = -1;
        this.direction += 0.0174533 * sign;
        this.directionVec.x = Math.cos(this.direction);
        this.directionVec.y = Math.sin(this.direction);
    }
    update(mousePosition : Vec2, isInMouseRange : boolean, flock : Boid[]) : void
    {
        this.speed = 2.5;
        if (isInMouseRange) {
            let avoidForce : Vec2 = new Vec2(0,0);
            avoidForce.x = this.center.x - mousePosition.x;
            avoidForce.y = this.center.y - mousePosition.y;
            avoidForce.normalize();
            avoidForce.scale(0.5);
            this.directionVec.add(avoidForce);
            this.speed += avoidForce.x * avoidForce.x * 16;
            this.speed += avoidForce.y * avoidForce.y * 16;
        }
        if (flock.length){
            this.separate(flock);
            this.align(flock);
            this.cohesion(flock);
        } else {
            this.explore();
        }

        this.directionVec.normalize();
        
    }
}

function averageBoidsDirection(arr : Boid[], current : Boid) : Vec2{
    let ret : Vec2 = new Vec2(0, 0);
    let i : number = 0;
    for (; i < arr.length; i++){
        ret.add(arr[i].directionVec);
    }

    ret.x /= i;
    ret.y /= i;
    return ret;
}
function findCenter(arr : Boid[], current : Boid) : Vec2{
    let ret : Vec2 = new Vec2(0, 0);
    let i : number = 0;
    for (; i < arr.length; i++){
        ret.add(arr[i].center);
    }

    ret.x /= i;
    ret.y /= i;
    return ret;
}

function generateBoids(w : number, h : number) : Boid[]
{
    let nb = Math.floor((w*h) /8000);
    if (nb > 100) 
        nb = 100;
    let array : Boid[] = [];
    for (let i : number = 0; i < nb; i++){
        array.push(new Boid(i));
        array[i].center = new Vec2(Math.floor(Math.random() * window.innerWidth), Math.floor(Math.random() * window.innerHeight));
        array[i].directionVec = new Vec2(Math.random(), Math.random());
        if (Math.floor(Math.random() * 2) % 2)
			array[i].directionVec.x *= -1;
		if (Math.floor(Math.random() * 2) % 2)
			array[i].directionVec.y *= -1;
        
        array[i].directionVec.normalize();
        array[i].direction = Math.atan2(array[i].directionVec.y, array[i].directionVec.x);
    }
    return array;
}

export {Boid, generateBoids};
import { Component } from "react";
import { contactStyle, generalStyle, messageInput } from "../utils/Theme";
import {smallInputs, contactLabel} from "../utils/Theme";
import { send } from 'emailjs-com';

interface ContactState {
    from_name: string;
    message: string;
    to_name: string;
    reply_to : string;
}
class Contact extends Component<any, ContactState>
{
    constructor(props : any){
        super(props);
        this.state = { from_name:"", to_name:"Nina", message:"", reply_to:"" };
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleSend = this.handleSend.bind(this);
    }
    handleMessageChange(event : any){
            this.setState({message: event.target.value});
    }
    handleFromChange(event : any){
        this.setState({from_name: event.target.value, reply_to:event.target.value});
    }
    async handleSend(){
        await send(
            'service_a4xetxz',
            'template_opszcuc',
            this.state,
            'f46rTDrj63p5NlI6O'
        ).then((response) => {
              alert('Your message was sent !');
            })
        .catch((err) => {
              alert('Something went wrong.. Please retry in a little while or contact me elsewhere.');
            });
            this.setState({ from_name:"", to_name:"Nina", message:"", reply_to:"" });
    }
    render(){
        return(
            <div style={generalStyle}>
                <div style={contactStyle}>
                    <form>
                        <label style={contactLabel}>Mail:</label><br/>
                        <input style={smallInputs} type="text" value={this.state.from_name} onChange={this.handleFromChange} />
                    </form>
                    <br/>
                    <br/>
                    <form style={{height:'50%'}}>
                        <label style={contactLabel}>Message:</label><br/>
                        <textarea style={messageInput}  value={this.state.message} onChange={this.handleMessageChange} /><br/>
                        <button style={{position:'relative', width:'80px' }} onClick={this.handleSend}>Send</button>
                    </form>
                    
                </div>
                
            </div>
        )
    }
}

export default Contact;
class Vec2
{
    x : number ;
    y : number ;
    constructor(x : number = 0, y : number = 0)
    {
        this.x = x;
        this.y = y;
    }
    add(vec : Vec2)
    {
        this.x += vec.x;
        this.y += vec.y;
    }
    sub(vec : Vec2)
    {
        this.x -= vec.x;
        this.y -= vec.y;
    }
    scale(scalar : number)
    {
        this.x *= scalar;
        this.y *= scalar;
    }
    normalize()
    {
        let len : number = Math.sqrt(this.x * this.x) + (this.y * this.y);
        if (len === 0){
            return;
        }
        this.x /= len;
        this.y /= len;
    }
    dist(other : Vec2) : number
    {
        return (Math.sqrt(Math.pow((this.x - other.x), 2) + Math.pow((this.y - other.y), 2)));
    }
    toroDist(other : Vec2) : number
    {
        let dx : number = Math.abs(other.x - this.x);
        let dy : number = Math.abs(other.y - this.y);
        if (dx > 0.5)
            dx = 1 - dx;
        if (dy > 0.5)
            dy = 1 - dy;
        return Math.sqrt(dx*dx + dy * dy);
    }
    set(other : Vec2) : void{
        this.x = other.x;
        this.y = other.y;
    }
}

export default  Vec2;
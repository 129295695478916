import { Component } from "react";
import { generalStyle, resumeStyle } from "../utils/Theme";

class Curriculum extends Component
{
    render(){
        return(
            <div style={generalStyle}>
                <div style={resumeStyle}>
               <h1>Resume</h1>
                
                <div>
                <h2>Programming skills and interests</h2>
               <h3>KNOWN LANGUAGES</h3>
               <p>C,  C++ and Typescript. Some weak notions of asm x86 and Python.</p>
               <h3>STRENGTHS</h3>
               <p>Resilient, curious, decent at debugging, a taste for “elegant” code and solutions, self-critical.</p>
               <h3>WEAKNESSES</h3>
               <p>Undeniable shortcomings in maths -that I’m actively working on-, not the best tester.</p>
               <h3>NOTABLE PROJECTS</h3>
               <p>During my school years I wrote Minishell, a basic shell with functional pipes and
                redirection features. Initially thought for two students, I decided to do this project alone
                during lockdown, which proved to be quite difficult. I was quite proud to complete it.
                Outside of school I worked on a simple implementation of boids (bird-like objects) in C
                using SDL2. It means a lot to me to be able to write laws of nature to reproduce
                behaviours virtually. I find it to be very rewarding.</p>
                </div>
                <h3>WHAT I WANT TO EXPLORE / IMPROVE</h3>
                <p>I want to get better at maths (at least enough to be better at graphics problematics), at
                OOP (study more design patterns), boost my skills in the languages I already know,
                and learn as much as possible in general.</p>
               <h2>Education</h2>
               <h3>42 — 2019 - PRESENT</h3>
               <p>One month long entrance test (called "piscine") passed in September 2019.</p>
               <div style={{height:'4%'}}/>
               <span style={{fontStyle:'italic', fontWeight:'bold'}}>Please contact me for my full resume</span>
               </div>
               
            </div>
        )
    }
}

export default Curriculum;
import CSS from 'csstype';

const Theme =
{
    Shark: "#23272b",
    Mako: "#3F434C",
    OxfordBlue: "#3a4856",
    MidGray: "#61606F",
    ShadyLady: "#B59CB1",
    Melanie: "#e5bbce"
}

const menuStyle : CSS.Properties = {
    listStyle:'none',
    textAlign:'end',
    textAlignLast:'left',
    position: 'fixed',
    zIndex: 2,
    width:'25%',
    bottom:'0%',
  };

const navStyle : CSS.Properties = {
    fontFamily:'helvetica',
    fontSize:'4.5vh',
    textOverflow: 'scale',
    fontWeight: 'bolder',
    textDecoration:'none',
    color:Theme.Melanie,
    backgroundColor:'transparent'
};

const navActive : CSS.Properties = {
    fontFamily:'helvetica',
    fontSize:'4.5vh',
    textOverflow: 'scale',
    fontWeight: 'bolder',
    textDecoration:'none',
    color:Theme.Mako,
    backgroundColor:'transparent'
};

const nameStyle : CSS.Properties = {
    fontSize:'8vh',
    fontWeight:'bold',
    color:'#0c0e0f',
    userSelect:'none',
    zIndex:1
}

const titleStyle : CSS.Properties = {
    fontSize: '5vh',
    fontWeight: 'bold',
    color:Theme.OxfordBlue,
    opacity:'70%',
    zIndex:1
}

const resumeStyle : CSS.Properties = {
    scrollBehavior:'smooth',
    color:Theme.Melanie,
    overflowX:'hidden',
    overflowY:'auto',
    right:'2%',
    width:'50%',
    height:'85vh',
    textAlign:'end',
    position:'fixed',
    display:'flex',
    flexDirection:'column',
    scrollbarWidth: 'none',  /* Firefox */
}

const generalStyle : CSS.Properties = {
    position:'fixed',
    height:'100%',
    width:'100%',
}

const smallInputs : CSS.Properties = {
    backgroundColor:Theme.OxfordBlue,
    borderColor:Theme.MidGray,
    width:'40%',
    color:Theme.Melanie,
    left:'12%',
    position:'absolute'
}

const messageInput : CSS.Properties = {
    backgroundColor:Theme.OxfordBlue,
    borderColor:Theme.MidGray,
    color:Theme.Melanie,
    width:'75%',
    height:'100%',
    resize:'none',
}

const contactStyle : CSS.Properties = {
    top:'12.5%',
    left:'12.5%',
    width:'75%',
    height:'75%',
    position:'absolute',
    overflowX:'hidden',
    overflowY:'auto',
}
const contactLabel : CSS.Properties = {
    color:Theme.ShadyLady,
    left:'2%',
    position:'absolute'
}
export default Theme;
export { navActive, navStyle, generalStyle,
     menuStyle, smallInputs, contactStyle,
      contactLabel, messageInput, nameStyle,
    titleStyle, resumeStyle };
import { Component, RefObject } from "react";
import { generalStyle } from "../utils/Theme";
import Theme from "../utils/Theme";
import React from "react";

const projectData : pCardData[] = [
    {name: 'FdF', description: 'Small program that displays a simple mesh.', imagePath:'fdf.png', link:'https://github.com/N-4-Nina/FdF'},
    {name: 'Minishell', description: 'Simple shell including pipes, redirection, etc...', imagePath:'', link:'https://github.com/N-4-Nina/Minishell'},
    {name: 'Cub3d', description: 'My first graphic project, implementing ray-tracing in C.', imagePath:'', link:'https://github.com/N-4-Nina/cub3d'},
    {name: 'Philosophers', description: 'An introduction to multi-threading and ressource sharing ', imagePath:'philosophers.png', link:'https://github.com/N-4-Nina/Philosophers'},
    {name: 'Webserv', description: 'Basic HTTP server supporting CGI, written in C++.', imagePath:'webserv.png', link:'https://github.com/N-4-Nina/Webserv'},
    {name: 'transcendance', description: 'Full stack (VueJS + NestJS + gresql) project allowing to play Pong against other player using the school API to login.', imagePath:'transcendance.png', link:'https://github.com/fyusuf-a/ft_transcendence'}
];

const otherprojectData : pCardData[] = [
    {name: 'Boids', description: 'Can you tell I like boids ? This is my first experiment with them using C and SDL2.', imagePath:'boids.png', link:'https://github.com/N-4-Nina/boids.git'}
]

interface pCardData{
    name : string,
    description :string,
    imagePath : string,
    link: string,
};

interface pCardProps{
    data: pCardData,
    displayHandler: (event : React.MouseEvent<HTMLDivElement> , data : pCardData)=>void,
};

interface pCardState{
    displayData: pCardData,
    color: string,
};

interface portFolioState{
    visibility: string,
    displayData: pCardData,
}

class   ProjectCard extends Component<pCardProps, pCardState> {
    
    constructor(props: pCardProps, state : pCardState){
        super(props);
        this.state = {displayData: this.props.data, color:Theme.OxfordBlue};
        this.mouseOver = this.mouseOver.bind(this);
        this.mouseExit = this.mouseExit.bind(this);
    }
    mouseOver(){
        this.setState((previous : pCardState)=>{
            previous.color = Theme.MidGray;
            return previous;
        })
    }
    mouseExit(){
        this.setState((previous : pCardState)=>{
            previous.color = Theme.OxfordBlue;
            return previous;
        })
    }
    render() {
        return (
            <div onClick={(event) => this.props.displayHandler(event, this.state.displayData)} 
            onMouseOver={this.mouseOver} onMouseLeave={this.mouseExit} 
            style={{ display: 'display-legacy', backgroundColor:this.state.color,
            borderRadius:'10px', borderWidth:'5em', minWidth:'9vw', padding:'2%', width:'20vh',
            fontSize:'2.5vh', fontWeight:'bold', color:Theme.ShadyLady, userSelect:'none',
            cursor:'pointer'}}>
                {this.props.data.name}
            </div>
        )
    }
}

class Portfolio extends Component<any, portFolioState>{
    wrapperRef : RefObject<HTMLDivElement>;
    constructor(props : any, state : portFolioState){
        super(props);
        this.state = {visibility: 'hidden', displayData: { name:"", description:"", imagePath:"", link:""}};
        this.displayHandler = this.displayHandler.bind(this);
        this.hideHandler = this.hideHandler.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
    }
    handleClickOutside(event : MouseEvent) {
        if (event.target instanceof Element && this.wrapperRef.current != null && !this.wrapperRef.current.contains(event.target)) {
          this.hideHandler();
        }
    }
    displayHandler(event : React.MouseEvent<HTMLDivElement> , data : pCardData){
        this.setState({
            visibility: 'visible',
            displayData: data
        });
    }
    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
    hideHandler(){
        
        this.setState({
            visibility: 'hidden',
            displayData: { name:"", description:"", imagePath:"", link:""}
        });
        
    }
    render() {
        let schoolCards : any[] = [];
        let otherCards : any[] = [];
        let i : number = 0;
        projectData.map((project)=> {
            let data : pCardData = {name: project.name, description: project.description, imagePath:project.imagePath, link: project.link };
            schoolCards.push(
                <ProjectCard key={i} displayHandler= {this.displayHandler}
                data={data}/>
            );
            i++;
        });
        i = 0;
        otherprojectData.map((project)=> {
            let data : pCardData = {name: project.name, description: project.description, imagePath:project.imagePath, link: project.link };
            otherCards.push(
                <ProjectCard key={i} displayHandler= {this.displayHandler}
                data={data}/>
            );
            i++;
        });
        return (
            <div style={generalStyle} >
                    <div ref={this.wrapperRef} style={{color:'#0c0e0f', borderRadius:'20px', display:'flex', flexDirection:'column', alignItems:'center', opacity:'95%', zIndex:2, backgroundColor:Theme.OxfordBlue, top:'10%', width:'64%', height:'62vh', overflowY:'scroll', left:'22%', position:'absolute', visibility:this.state.visibility as DocumentVisibilityState}}>
                        <h1>{this.state.displayData.name}</h1>
                        <p style={{fontSize:'20px',}}>{this.state.displayData.description}</p>
                        <img alt='A screenshot of the project' src={this.state.displayData.imagePath} style={{width:'25vh'}}/>
                        <a style={{ textDecoration:'none', }} href={this.state.displayData.link}>
                        <div style={{width:'fit-content', display:'flex', flexDirection:'column', justifyContent: 'center', alignItems:'center', alignSelf:'center', textAlign:'center'}}>
                            
                                <img style={{ width:'7vh'}} src="/github-mark-white.png" alt="github logo"/>
                                <span style={{  color:'#0c0e0f' }}>view it on github</span>
                        </div>
                        </a>

                    </div>
                    
                    <div style={{left:'33%', width:'42%', height:'65vh', top:'10%', position:'fixed', overflowX:'hidden', overflowY:'auto', }}>
                    <h1>
                        School projects
                    </h1>
                    <div style={{ flexWrap:'wrap', rowGap:'10px', justifyContent:'space-around',  display: 'flex', flexDirection:'row', alignItems:'center'}}>
                        {schoolCards}
                    </div>
                    <h1>
                        Other
                    </h1>
                    <div style={{ flexWrap:'wrap', rowGap:'10px', justifyContent:'space-around',  display: 'flex', flexDirection:'row', alignItems:'center'}}>
                        {otherCards}
                    </div>
                        <span style={{color:Theme.Melanie, fontStyle:'italic'}}>More soon</span>
                    </div>
            </div>
        )
    }
}

export default Portfolio;
import './App.css';
import BoidsBg from './Vues/BoidsBg';
import { Route, NavLink, HashRouter, Routes } from 'react-router-dom';
import Curriculum from './Vues/Resume';
import Contact from './Vues/Contact';
import Portfolio from './Vues/Portfolio';
import { Component } from 'react';
import Theme, { navStyle, menuStyle, navActive } from './utils/Theme';

class Menu extends Component {
  render() {
    return(
        <ul style={menuStyle}>
        <li><NavLink   style={({isActive}) => (isActive ? navActive : navStyle)}  to='/'>Home</NavLink></li>
          <li><NavLink style={({isActive}) => (isActive ? navActive : navStyle)} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/curriculum'>Resume</NavLink></li>
          <li><NavLink style={({isActive}) => (isActive ? navActive : navStyle)} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/portfolio'>Portfolio</NavLink></li>
          <li><NavLink style={({isActive}) => (isActive ? navActive : navStyle)} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/contact'>Contact</NavLink></li>
        </ul>
    )
  }
}

function App() {
  return (
    <div className="App">
    
    <HashRouter>
       <div style={{position:'fixed', width:'100%', height:'100%', zIndex:1, backgroundColor:Theme.Shark}}>
       <Menu/>
        <Routes>
          <Route path="/" element={<BoidsBg/>}/>
          <Route path="/curriculum" element={<Curriculum/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/portfolio" element={<Portfolio/>}/>
        </Routes>
        </div>
        
      </HashRouter>
      </div>
  );
}

export default App;
export { Menu };
import { Component } from "react";
import { Stage, Layer, Rect, Line } from "react-konva";
import Vec2 from "../utils/Vec2";
import { Boid, generateBoids } from "../Components/Boids";
import React from "react";
import Theme, { nameStyle, titleStyle }  from "../utils/Theme";

interface State {
    boids: Boid[];
    mousePos: Vec2;
}
class BoidsBg extends Component<any, State> {

    constructor(props : any)
    {
        super(props);  
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.update = this.update.bind(this);
        this.state = {
            boids: generateBoids(window.innerWidth, window.innerHeight),
            mousePos: new Vec2(0,0),
        }
    }
    handleMouseMove(event : React.MouseEvent) : void {
        this.setState({
          mousePos: new Vec2(event.clientX, event.clientY),
        });
      }
    update() : void
    {
        let fovArray : Boid[][] = [];
        let inmouseRange : boolean[] = [];
        this.setState((previous: State) : State =>{
            for(let boid of previous.boids)  {

                if (boid.center.dist(previous.mousePos) <= 200)
                    inmouseRange[boid.id] = true;
                else
                    inmouseRange[boid.id] = false;
                
                fovArray[previous.boids.indexOf(boid)] = [];
                for( let other of previous.boids){
                    if (boid !== other && boid.hasInFOV(other))
                        fovArray[boid.id].push(new Boid(other.id).clone(other));
                }
            };
            for(let boid of previous.boids) {
                boid.update(this.state.mousePos, inmouseRange[boid.id], fovArray[boid.id], );
                boid.move();
                boid.wrap(window.innerWidth, window.innerHeight);
            };
            return previous;
        })

        window.requestAnimationFrame(this.update);
    }
    componentDidMount(): void {
        this.update();
    }

    render () {
        const boidShapes : any[] = [];
        this.state.boids.map((boid)=> (
            boidShapes.push(
            <Line
            key={this.state.boids.indexOf(boid)}
            useCache={true}
            width={10}
            height= {10}
            stroke={boid.color}
            points={boid.getPoints()}
            closed
            />)
          ))
      return (
        
        <div onMouseMove={(e) => this.handleMouseMove(e)}>
            <div style={{
                position:'fixed',
                right:'2%',
                userSelect:'none',
                textAlign:'end',
                zIndex:1
            }}>
                <div style={nameStyle}>Nina Bouchaud</div>
                <div style={titleStyle}>aspiring game developer</div>
            </div>
            <Stage width={window.innerWidth} height={window.innerHeight}>
            
                <Layer>
                <Rect fill={Theme.Shark} x={0} y={0} width={window.innerWidth} height={window.innerHeight}/>
                {boidShapes}
                
            </Layer>
              
          </Stage>
        
        </div>
      );
    }
  }
  export default BoidsBg;
